@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";

.componentCapsuleProducts {
  @include positioning.flex-globally;

  position: relative;
  width: 100%;

  .product {
    height: 28px;
    width: 28px;
    border: 2px solid var(--white);
    background: var(--white);
    border-radius: 100%;
    margin-right: 9px;
    position: relative;
    float: left;
  }
}