@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/boxs";

.componentWebsiteHead {

  &.blog, &.schedule {
    visibility: visible;
  }

  width: 100%;
  position: relative;
  display: flex;

  .caseLogo {
    border-radius: 18px;
    padding: 5.5px 12px 5.5px 7px;
    display: flex;
    margin-left: -7px;
    flex-direction: row;
    z-index: 10;
    gap: 10.45px;
    cursor: pointer;


    &:hover {
      background: var(--grey-sur);
    }

    .logoContainer {
      width: 28.5px;
      height: 28.5px;

      .logo {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      margin-top: 1px;
      font-size: 21px;
      @include texts.font("bold");
    }
  }

  .cases {
    margin-left: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5%;

    .case {
      display: flex;
      flex-direction: row;
      gap: 10px;
      cursor: pointer;
      border-radius: 18px;
      padding: 10px 12px;
      font-size: 17px;
      justify-content: center;;
      background: transparent;

      @include texts.font("semi-bold");

      &.products, .use, .pricing {
        visibility: visible;
      }

      &:hover {
        background: var(--grey-sur);
      }

      .icon {
        margin-top: 4px;
        width: 14px;
        height: 14px;
        @include icons.ico("black-bottom");
      }

      .subMenu {
        position: absolute;
        top: 50px;
        padding-top: 14px;
        border-radius: 15px;
        z-index: 9;
        display: none;

        @include boxs.sur;

        .separator {
          position: absolute;
          height: 50px;
          top: -25px;
          width: 200px;
          left: calc(50% - 100px);
        }
      }
    }
  }

  .caseAccount {
    background: var(--black);
    font-size: 15px;
    color: var(--white);
    height: fit-content;
    position: relative;
    white-space: nowrap;
    border-radius: 25px;
    margin-left: auto;
    padding: 12px 12px;

    &:hover {
      opacity: 0.8;
    }
  }

  .casesMobile {
    display: none;

    .caseMobile {
      margin-left: auto;
      z-index: 4;
      display: flex;
      cursor: pointer;
      border-radius: 18px;
      height: 42px;
      min-width: 42px;
      align-items: center;
      justify-content: center;
      padding: 0px 20px;

      @include texts.font("semi-bold");

      &:hover {
        background: var(--grey-sur);
      }

      &.open {
        .icon {
          width: 16px;
          height: 16px;

          @include icons.ico("black-close-bold");
        }
      }

      &.bars {
        padding: 0;
      }

      .icon {
        width: 20px;
        height: 20px;

        @include icons.ico("black-mobile-menu");
      }
    }
  }


  .mobile {
    position: fixed;
    left: 36px;
    top: 13px;
    width: calc(100% - 84px);
    display: none;
    background: var(--grad-multi);
    border-radius: 14px;
    padding: 54px 6px 22px;
    z-index: 1;
    margin-top: -10px;
    height: calc(100% - 100px);

    &:before {
      content: "";
      z-index: -1;
      border-radius: 10px;
      position: absolute;
      inset: 0;
      margin: 6px;
      background: white;
    }
  }

  @media (max-width: 480px) {
    .mobile {
      width: calc(100% - 20px);
      left: 4px;
    }
  }

  @media (max-width: 930px) {
    justify-content: space-between;

    .caseLogo {
      gap: 15px;

      .logoContainer {
        width: 30px;
        height: 30px;
      }

      .title {
        margin-top: 3px;
        font-size: 20px;
      }
    }

    .cases {
      display: none;
    }

    .caseAccount {
      display: none;
    }

    .casesMobile {
      display: flex;
    }
  }
}