.componentWebsitePage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .head {
    width: 100%;
    height: 85px;
    position: relative;

    .headFixed {
      height: 64px;
      width: 100%;
      position: absolute;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &.fixed {
        z-index: 999;
        position: fixed;
        box-shadow: 0 4px 10px 0 #0000000d;
        background: var(--white);
      }
    }
  }

  .content {
    width: 100%;
    padding: 0px;
    max-width: 1150px;

    .contents {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 50px;
    }

    .container {
      width: 100%;
    }
  }

  .footerContainer {
    margin: 70px 0 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: linear-gradient(to bottom, var(--grey-transparent), var(--grey-gradient));
  }

  @media (max-width: 1250px) {
    .content {
      width: calc(100% - 100px);
      padding: 0px 50px;

      .contents {
        gap: 30px;
      }
    }

    .footerContainer {
      margin: 40px 0 0 0;
    }
  }

  @media (max-width: 480px) {
    .content {
      width: calc(100% - 40px);
      padding: 0px 20px;
    }
  }
}