import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsiteHeadSubmenuComponent} from "../WebsiteHeadSubmenuComponent";
import {WebsiteHeadSubmenuContentProductsComponent} from "../content/WebsiteHeadSubmenuContentProductsComponent";

export class WebsiteHeadSubmenuModelsProductsComponent extends WebsiteHeadSubmenuComponent {
    contentComponent(): Component {
        return new WebsiteHeadSubmenuContentProductsComponent();
    }
}