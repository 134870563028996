import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {CapsuleComponent} from "../CapsuleComponent";
import {ProductType} from "../../../../../../models/product/ProductType";
import * as s from "./capsule-products.scss";

export class CapsuleProductsComponent extends Component {

    public soon: boolean;

    constructor(soon?: boolean) {
        super();

        this.soon = soon ? soon : false;

        //language=HTML
        this.template = `
            <div class="${s.componentCapsuleProducts}">
                <div class="${s.product}">
                    ${this.draw(new CapsuleComponent(ProductType.BLOG, soon))}
                </div>
                <div class="${s.product}">
                    ${this.draw(new CapsuleComponent(ProductType.HELPCENTER, soon))}
                </div>
                <!--<div class="${s.product}">
                    ${this.draw(new CapsuleComponent(ProductType.INBOX, soon))}
                </div>
                <div class="${s.product}">
                    ${this.draw(new CapsuleComponent(ProductType.PUBLISHER, soon))}
                </div>
                <div class="${s.product}">
                    ${this.draw(new CapsuleComponent(ProductType.STATISTICS, soon))}
                </div>
                <div class="${s.product}">
                    ${this.draw(new CapsuleComponent(ProductType.CONTACTS, soon))}
                </div>
                <div class="${s.product}">
                    ${this.draw(new CapsuleComponent(ProductType.LIVECHAT, soon))}
                </div>
                -->
            </div>
        `;
    }
}