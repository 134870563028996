import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsiteHeadSubmenuComponent} from "../WebsiteHeadSubmenuComponent";
import {WebsiteHeadSubmenuContentScheduleComponent} from "../content/WebsiteHeadSubmenuContentScheduleComponent";

export class WebsiteHeadSubmenuModelsScheduleComponent extends WebsiteHeadSubmenuComponent {
    contentComponent(): Component {
        return new WebsiteHeadSubmenuContentScheduleComponent();
    }
}