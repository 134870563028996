import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-head-submenu-content.scss";

export abstract class WebsiteHeadSubmenuContentComponent extends Component {
    openColumn(column: Component) {
        column.el(s.cases).displayFlex();
        column.el(s.title).addClass(s.open);
    }

    closeColumn(column: Component) {
        column.el(s.cases).displayHide();
        column.el(s.title).removeClass(s.open);
    }
}