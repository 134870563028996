import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-slider-card.scss";
import {WebsiteBrandComponent} from "../../brand/WebsiteBrandComponent";
import {ProductType} from "../../../../../../../models/product/ProductType";
import {Resources} from "../../../../../../../resources/Resources";


export class WebsiteSliderCardComponent extends Component {

    public rightContainer: Component;
    public url: string;

    constructor(p: { productType: ProductType, title: string, subtitle: string, url: string }) {
        super();

        this.url = p.url;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteSliderCard}">
                <div class="${s.left}">
                    ${this.draw(new WebsiteBrandComponent(p.productType))}
                    <div class="${s.article}">
                        <h2 class="${s.title}">
                            ${p.title}
                        </h2>
                        <p class="${s.subtitle}">
                            ${p.subtitle}
                        </p>
                        ${p.url != '/' ? `                   
                            <a class="${s.more}" href="${p.url}">
                                ${Resources.t("words.more")}
                                <i class="${s.arrow}"></i>
                            </a>` : ''}
                    </div>
                </div>
                <div class="${s.right}"></div>
            </div>
        `;
    }

    commit() {
        this.rightContainer = this.el(s.right);

        this.renderRight();
        super.commit();
    }

    renderRight() {

    }

}