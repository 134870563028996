@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteProduct {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(5, 1fr);

  .brandContainer {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .imageContainer {
    grid-column-start: 3;
    grid-column-end: end;
    grid-row-start: 1;
    position: relative;
    grid-row-end: end;
  }

  .cardTexts {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    position: relative;
    grid-row-end: 6;
    min-width: 350px;
    padding: 38px 52px 62px 52px;
    background: var(--white);
    border-radius: 15px;
    box-shadow: 0 0 32.85px var(--black-alpha-middle);

    .title {
      font-size: 42px;

      @include texts.font("bold");
    }

    .inbox {
      background: var(--grad-green);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .statistics {
      background: var(--grad-purple);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .livechat {
      background: var(--grad-blue-button);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .mail, .serviceclient {
      background: var(--grad-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .text {
      margin-top: 20px;
      font-size: 18px;
      @include texts.font("semi-bold");
    }

    .round {
      height: 120px;
      width: 120px;
      right: -60px;
      top: -60px;
      position: absolute;
      border-radius: 100%;
      background: var(--white);
      box-shadow: 0 0 32.85px var(--black-alpha-middle);
      display: flex;
      justify-content: center;
      align-items: center;

      &.prestashop {
        .icon {
          @include icons.ico("colored-prestashop");
        }
      }

      &.wix {
        .icon {
          @include icons.ico("black-wix");
        }
      }

      &.wordpress {
        .icon {
          @include icons.ico("colored-wordpress");
        }
      }

      &.shopify {
        .icon {
          @include icons.ico("colored-shopify");
        }
      }

      .icon {
        height: 70px;
        width: 70px;
      }
    }

    .promo {
      background: var(--grey-sur);
      border-radius: 12px;
      padding: 12px;
      margin-top: 15px;
      font-size: 14px;
    }

    .button {
      background: var(--black);
      color: var(--white);
      width: fit-content;
      padding: 14px 22px;
      margin-top: 20px;
      border-radius: 31px;
      font-size: 17px;
      display: flex;
      align-items: center;
      transition: 0.1s ease-in-out;

      &:hover {
        opacity: 0.8;
      }

      .icon {
        height: 22px;
        width: 22px;
        margin-right: 8px;
        flex: 0 0 22px;

        &.shopify {
          @include icons.ico("colored-shopify");
        }

        &.prestashop {
          @include icons.ico("colored-prestashop");
        }

        &.wix {
          @include icons.ico("black-wix");
        }

        &.wordpress {
          filter: var(--icon-white);
          @include icons.ico("black-lineal-wordpress");
        }
      }

      .arrow {
        height: 14px;
        width: 14px;
        flex: 0 0 14px;
        margin-left: 10px;
        filter: var(--icon-white);

        @include icons.ico("black-right-arrow");
      }
    }
  }

  @media (max-width: 1050px) {
    grid-template-rows: repeat(4, 1fr);

    .cardTexts {
      .title {
        font-size: 40px;
      }

      .text {
        margin-top: 30px;
      }
    }
  }

  @media (max-width: 750px) {
    .brandContainer {
      grid-column-end: 4;
    }

    .imageContainer {
      grid-column-start: 4;
    }

    .cardTexts {
      min-width: 300px;
      padding: 25px 40px 50px 40px;

      .title {
        font-size: 35px;
      }

      .text {
        margin-top: 20px;
        font-size: 16px;
      }
    }
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;

    .brandContainer {
      order: 1;
    }

    .imageContainer {
      order: 3;
      margin-top: 30px;
    }

    .cardTexts {
      margin-top: 20px;
      order: 2;
      min-width: 0;
      padding: 0;
      background: transparent;
      box-shadow: none;

      .title {
        font-size: 33px;
      }

      .text {
        margin-top: 20px;
        font-size: 18px;
      }

      .round {
        width: 56px;
        height: 56px;
        right: 0px;

        .icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}