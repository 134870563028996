import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsiteHeadSubmenuComponent} from "../WebsiteHeadSubmenuComponent";
import {WebsiteHeadSubmenuContentPricingComponent} from "../content/WebsiteHeadSubmenuContentPricingComponent";

export class WebsiteHeadSubmenuModelsPricingComponent extends WebsiteHeadSubmenuComponent {
    contentComponent(): Component {
        return new WebsiteHeadSubmenuContentPricingComponent();
    }
}