@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";

.componentWebsiteSliderFlat {
  position: relative;

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 19.5px;
    overflow: hidden;

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;

      .entry {
        width: 100%;
        height: 100%;
        display: none;

        &.absolute {
          position: absolute;
        }

        &.visible {
          display: block !important;

          @include positioning.flex-globally;
        }
      }
    }
  }

  .switchButtons {
    position: absolute;
    top: calc(50% - 41px);
    width: 100%;

    .left {
      float: left;

      .icon {
        @include icons.ico("black-left-arrow");
      }
    }

    .right {
      float: right;

      .icon {
        @include icons.ico("black-right-arrow");
      }
    }

    .left, .right {
      border-radius: 50%;
      background: var(--white);
      width: 82px;
      height: 82px;
      box-shadow: 0 0 50px var(--black-alpha-middle);
      cursor: pointer;
      @include positioning.flex-globally;

      .icon {
        width: 25px;
        height: 25px;
      }
    }
  }

  @media (max-width: 480px) {
    .container {
      margin-left: 0;
      background: var(--grey-sur);
    }

    .switchButtons {
      width: calc(100% - 50px);
      margin-left: 25px;

      .left, .right {
        width: 50px;
        height: 50px;

        .icon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}