import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-head-submenu.scss";

export class WebsiteHeadSubmenuComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteHeadSubmenu}">
                ${this.draw(this.contentComponent())}
            </div>
        `;
    }

    contentComponent(): Component {
        return undefined;
    }
}