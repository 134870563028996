import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsiteHeadSubmenuComponent} from "../WebsiteHeadSubmenuComponent";
import {WebsiteHeadSubmenuContentUseCaseComponent} from "../content/WebsiteHeadSubmenuContentUseCaseComponent";

export class WebsiteHeadSubmenuModelsUseCaseComponent extends WebsiteHeadSubmenuComponent {
    contentComponent(): Component {
        return new WebsiteHeadSubmenuContentUseCaseComponent();

    }
}