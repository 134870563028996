@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentWebsiteSolutions {
  visibility: visible;

  .head {
    padding: 15px 0 15px 0;
    border-bottom: 1px solid var(--grey-sur);

    .title {
      font-size: 44px;
      @include texts.font("bold");
    }

    .buttons {
      margin-top: 23px;
      display: flex;
      flex-direction: row;
      gap: 15px;
      flex-wrap: wrap;

      .button {
        display: flex;
        flex-direction: row;
        gap: 12px;
        padding: 10px 12px;
        border-radius: 24px;
        cursor: pointer;
        font-size: 18px;
        @include texts.font("semi-bold");

        .plus {
          padding: 4px 5px;
          border-radius: 6px;
          background: var(--red-notify);
          color: var(--white);
          font-size: 10px;
          height: 14px;
          @include texts.font("semi-bold");
        }

        &:hover, &[data-selected] {
          background: var(--grey-sur);
        }

        &.blog, &.inbox, &.livechat, &.mails, &.frHelpcenter, &.contacts {
          visibility: visible;
        }
      }

      .separation {
        display: inline;
        border-radius: 4px;
        width: 6px;
        background: var(--grey-sur);
        height: 42px;
      }
    }
  }

  .contentContainer {
    position: relative;
    height: fit-content;
  }

  @media (max-width: 480px) {
    .head {
      border: none;

      .title {
        font-size: 40px;
        text-align: center;
      }

      .buttons {
        margin-top: 30px;
        flex-wrap: wrap;
        row-gap: 5px;
        gap: 10px;
        justify-content: center;

        .separation {
          display: none;
        }
      }
    }
    .contentContainer {
      height: 100%
    }
  }
}