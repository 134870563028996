import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-slider-flat.scss";

export class WebsiteSliderFlatComponent extends Component {
    public components: { button?: Component, func: () => Component } [];

    public renderedComponents: Component[];
    public currentRenderedComponent: Component;

    public contentContainer: Component;
    public switchButtonsContainer: Component;

    public indexVisible: number;

    public indiceButton: boolean;

    public sliding: boolean;

    constructor(p: { buttons: boolean, components: { button?: Component, func: () => Component } [] }) {
        super();
        this.components = p.components;
        this.renderedComponents = [];
        this.indexVisible = 0;

        this.indiceButton = p.buttons;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteSliderFlat}">
                <div class="${s.container}">
                    <div class="${s.content}"></div>
                </div>
                <div class="${s.switchButtons}"></div>
            </div>
        `;
    }

    commit() {
        this.contentContainer = this.el(s.content);
        this.switchButtonsContainer = this.el(s.switchButtons);
        this.components.forEach(entry => {
            //language=HTML
            let slide = this.contentContainer.append(`
                <div class="${s.entry}">${this.draw(entry.func())}</div>`);

            this.renderedComponents.push(slide);

            entry.button?.onClick(() => {
                let index = this.renderedComponents.indexOf(slide);
                if (index != this.indexVisible) {
                    this.slideAtIndex(this.renderedComponents.indexOf(slide))
                }
            })
        });

        this.renderContent();
        this.renderButtonLeft();
        this.renderButtonRight();
        super.commit();
    }

    /**
     * render
     */
    renderButtonLeft() {
        if (this.indiceButton == true) {
            //language=HTML
            let button = this.switchButtonsContainer.append(`
                <div class="${s.left}">
                    <div class="${s.icon}"></div>
                </div>
            `);

            button.onClick(() => this.slideLeft());
        }
    }

    renderButtonRight() {
        if (this.indiceButton == true) {
            //language=HTML
            let button = this.switchButtonsContainer.append(`
                <div class="${s.right}">
                    <div class="${s.icon}"></div>
                </div>
            `);

            button.onClick(() => this.slideRight());
        }
    }

    async renderContent(animation?: string) {
        let nextComponent = this.renderedComponents[this.indexVisible];
        this.components.map(component => component.button).forEach(button => {
            button.removeAttribute("data-selected");
        });
        this.components[this.indexVisible].button?.setAttribute("data-selected", "true");

        if (animation) {
            this.sliding = true;
            this.currentRenderedComponent.addClass(s.absolute);
            nextComponent.addClass(s.visible);
            nextComponent.setStyle(`transform: scale(0.8) translateX(${animation == "right" ? "100%" : "-100%"});`);
            await this.wait([
                this.currentRenderedComponent.translate({
                    translateX: animation == "right" ? -this.contentContainer.getWidth() : this.contentContainer.getWidth(),
                    delay: 400,
                    scale: 0.8
                }),
                nextComponent.translate({
                    translateX: 0,
                    scale: 1,
                    delay: 400
                })
            ]);
            this.sliding = false;
        }

        if (this.currentRenderedComponent) {
            this.currentRenderedComponent.removeClass(s.absolute);
            this.currentRenderedComponent.removeClass(s.visible);
        }

        this.currentRenderedComponent = nextComponent;
        this.currentRenderedComponent.addClass(s.visible);


    }

    /**
     * slide
     */

    slideLeft() {
        if (!this.sliding) {
            if (this.indexVisible == 0) {
                this.indexVisible = this.renderedComponents.length - 1;
            } else {
                this.indexVisible--;
            }

            this.renderContent("left");
        }
    }

    slideRight() {
        if (!this.sliding) {
            if (this.indexVisible == this.renderedComponents.length - 1) {
                this.indexVisible = 0;
            } else {
                this.indexVisible++;
            }

            this.renderContent("right");
        }
    }


    slideAtIndex(index: number) {
        if (!this.sliding) {
            let direction = this.indexVisible > index ? 'left' : 'right';
            this.indexVisible = index;
            this.renderContent(direction);
        }
    }
}