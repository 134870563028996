import * as s from "./website-head-submenu-content.scss";
import {ProductType} from "../../../../../../../models/product/ProductType";
import {Network} from "../../../../../../../network/Network";
import {Resources} from "../../../../../../../resources/Resources";
import {CapsuleComponent} from "../../../../../../panel/components/components/capsule/CapsuleComponent";
import {WebsiteHeadSubmenuContentComponent} from "./WebsiteHeadSubmenuContentComponent";

export class WebsiteHeadSubmenuContentBlogComponent extends WebsiteHeadSubmenuContentComponent {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <a  target="_blank" href="${process.env.BLOG_URL}" class="${s.componentWebsiteHeadSubmenuContent}">
                <div class="${s.column}">
                    <h6 class="${s.title}">
                       Blog
                        <span class="${s.arrow}"></span>
                    </h6>
                </div>
            </a>
        `;
    }
}